(function() {
  var responseParent = document.getElementById('responseContainer');
  var btnShowResponse = document.getElementById('responseTrigger');


  /** Grabs responses from JSON.
   */
  (function() {
    var req = new XMLHttpRequest();

    req.overrideMimeType('application/json');
    req.open('GET', 'data/responses.json', true);
    req.onload = function() {
      var msgs = JSON.parse(req.responseText);

      addResponses(msgs);
      btnShowResponse.addEventListener('click', randomNoRepeats(msgs), false);
    };
    req.send(null);
  })();


  /** Adds all responses to DOM.
   * @param {object} msgs Objects from the parsed JSON.
   */
  function addResponses(msgs) {
    for (var i = 0; i < msgs.length; i++) {
      var response = msgs[i];
      var el;

      response.index = i;

      if (response.type == 'video') {
        var videosrc = document.createElement('source');
        var videotrack = document.createElement('track');
        var videodesc = document.createElement('track');

        el = document.createElement('video');
        el.setAttribute('autoplay', '');
        el.setAttribute('loop', '');
        el.setAttribute('muted', '');
        el.setAttribute('playsinline', '');
        videosrc.setAttribute('src', response.content);
        videosrc.setAttribute('type', 'video/mp4');
        el.append(videosrc);
        videotrack.setAttribute('src', './img/captions_en.vtt');
        videotrack.setAttribute('kind', 'captions');
        videotrack.setAttribute('srclang', 'en');
        el.append(videotrack);
        videodesc.setAttribute('src', './img/captions_en.vtt');
        videodesc.setAttribute('kind', 'descriptions');
        videodesc.setAttribute('srclang', 'en');
        el.append(videodesc);
      } else if (response.type == 'text') {
        el = document.createElement('p');
        el.innerHTML = response.content;
      }
      el.id = 'response-' + i;
      el.setAttribute('hidden', '');
      responseParent.append(el);
    }
  }


  /** Shows random response when button is clicked, without repeats.
   * @param {object} msgs Objects from the parsed JSON.
   * @return {object} The original response array one all responses are shown.
   */
  function randomNoRepeats(msgs) {
    var copy = msgs.slice(0);
    var children = responseParent.children;

    return function() {
      if (copy.length < 1) {
        copy = msgs.slice(0);
      }

      var index = Math.floor(Math.random() * copy.length);
      var id = copy[index].index;
      var elid = 'response-' + id;

      copy.splice(index, 1);
      for (var i = 0; i < children.length; i++) {
        if (children[i].id == elid) {
          // Reset if video
          if (children[i].nodeName == 'VIDEO') {
            children[i].currentTime = 0;
          }
          children[i].removeAttribute('hidden');
        } else {
          children[i].setAttribute('hidden', '');
        }
      }
    };
  }
})();
