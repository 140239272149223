/** Gets next sibling based on passed selector.
 * @param {object} elem The passed target element.
 * @param {string} selector The passed selector.
 * @return {object} The next sibling matching the selector.
 */
function getNextSibling(elem, selector) {
  // Get the next sibling element
  var sibling = elem.nextElementSibling;

  // If there's no selector, return the first sibling.
  if (!selector) return sibling;

  // If the sibling matches the selector, use it.
  // If not, jump to the next sibling and continue the loop.
  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.nextElementSibling;
  }
}


// Avoid `console` errors in browsers that lack a console.
(function() {
  var method;
  var noop = function() {};
  var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn',
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
})();


/** Sticky hover fix from
 * http://www.javascriptkit.com/dhtmltutors/sticky-hover-issue-solutions.shtml
 */
(function() {
  var isTouch = false; // var indicating current input type (touch vs no touch)
  var isTouchTimer;
  var curRootClass = ''; // var indicating current document root class

  /** Adds can-touch class to root after a touch event.
   * @param {string} e The event.
   */
  function addtouchclass(e) {
    clearTimeout(isTouchTimer);
    isTouch = true;
    if (curRootClass != 'can-touch') { // add "can-touch' class if needed
      curRootClass = 'can-touch';
      document.documentElement.classList.add(curRootClass);
    }
    isTouchTimer = setTimeout(function() {
      isTouch = false;
    }, 500); // delay so removetouchclass isn't fired immediately after a touch
  }

  /** Removes can-touch class from root after a mouse event.
   * @param {string} e The event.
   */
  function removetouchclass(e) {
    if (!isTouch && curRootClass == 'can-touch') {
      isTouch = false;
      curRootClass = '';
      document.documentElement.classList.remove('can-touch');
    }
  }

  document.addEventListener('touchstart', addtouchclass, false);
  document.addEventListener('mouseover', removetouchclass, false);
})();
