(function() {
  var fieldToggles = document.querySelectorAll('[data-fieldset-toggle]');
  var txtTypes = 'input[type=email], input[type=text],' +
    'input[type=url], textarea';
  var txtFields = document.querySelectorAll(txtTypes);


  // Toggle form fields based on selected radio button
  [].forEach.call(fieldToggles, function(el) {
    var name = el.getAttribute('data-fieldset-toggle');
    var fieldPrefix = 'fieldset-' + name + '-';
    var radios = el.querySelectorAll('input[type=radio]');
    var fields = document.querySelectorAll('[data-fieldset=' + name + ']');

    for (var i = 0; i < radios.length; i++) {
      radios[i].addEventListener('click', function() {
        var id = fieldPrefix + this.value;

        for (var i = 0; i < fields.length; i++) {
          fields[i].setAttribute('hidden', '');
        }
        document.getElementById(id).removeAttribute('hidden');
      }, false);
    }
  });


  /** Updates text field state based on length of value.
   * @param {object} target The passed target element.
   */
  function updateFieldState(target) {
    var numChars = target.value.length;

    if (numChars > 0) {
      target.classList.add('is-populated');
    } else {
      target.classList.remove('is-populated');
    }
  }

  /** Updates character count helper based on value of passed textfield.
   * @param {object} target The passed target element.
   */
  function updateCharCount(target) {
    var elCountParent = getNextSibling(target, 'small');
    var elCount = elCountParent.querySelector('.js-currentChar');
    var elMax = elCountParent.querySelector('.js-maxChar');

    if (!elCount) return;

    var max = target.getAttribute('maxlength');

    elMax.innerHTML = max;
    elCount.innerHTML = target.value.length;
  }

  // Update state of text fields based on value
  [].forEach.call(txtFields, function(el) {
    el.addEventListener('input', function() {
      var target = event.target;
      updateFieldState(target);
      updateCharCount(target);
    }, false);
  });
})();
