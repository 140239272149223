(function() {
  var dialogs = document.getElementsByClassName('js-dialog');
  var dialogToggles = document.getElementsByClassName('js-dialog-toggle');
  var header = document.getElementById('header');
  var main = document.getElementById('main');


  /** Toggles dialogs
   * @param {object} trigger The node the triggering event attached to
   */
  function toggleDialog(trigger) {
    var action = trigger.getAttribute('data-action');
    var dialogId = trigger.getAttribute('data-target');
    var openTrigger = document.getElementsByClassName('js-dialog-trigger')[0];

    // If trigger is the dialog backdrop
    if (dialogId == null || dialogId == undefined) dialogId = trigger.id;

    var dialog = document.getElementById(dialogId);
    var focusable = dialog.querySelectorAll('button, [href], input, select,' +
      'textarea, [tabindex]:not([tabindex="-1"])');
    var firstFocusable = focusable[0];

    if (action == 'open') {
      trigger.classList.add('js-dialog-trigger');
      document.documentElement.classList.add('is-no-overflow');
      dialog.hidden = false;
      dialog.setAttribute('aria-modal', 'true');
      header.setAttribute('inert', '');
      header.setAttribute('aria-hidden', 'true');
      main.setAttribute('inert', '');
      main.setAttribute('aria-hidden', 'true');
      setTimeout(function() {
        firstFocusable.focus();
      }, 350);
    } else {
      document.documentElement.classList.remove('is-no-overflow');
      [].forEach.call(dialogs, function(el) {
        el.hidden = true;
        el.removeAttribute('aria-modal');
      });
      header.removeAttribute('inert');
      header.removeAttribute('aria-hidden');
      main.removeAttribute('inert');
      main.removeAttribute('aria-hidden');
      if (openTrigger != null && openTrigger != undefined) {
        openTrigger.focus();
        openTrigger.classList.remove('js-dialog-trigger');
      }
    }
  }

  // Open/close dialogs when user clicks backdrop/relevant buttons
  [].forEach.call(dialogToggles, function(el) {
    el.addEventListener('click', function() {
      toggleDialog(event.currentTarget);
    }, false);
  });

  [].forEach.call(dialogs, function(el) {
    el.addEventListener('click', function() {
      if (event.currentTarget !== event.target) return;
      toggleDialog(event.currentTarget);
    }, false);
  });

  // Close dialogs when user presses Esc
  document.addEventListener('keydown', function() {
    if (event.keyCode === 27) {
      document.body.focus();
      toggleDialog(main);
    }
  }, false);
})();
