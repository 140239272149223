(function() {
  var html = document.documentElement;
  var radios = document.querySelectorAll('input[name=Theme]');

  [].forEach.call(radios, function(el) {
    el.addEventListener('click', function() {
      var value = el.getAttribute('value');
      var classes = html.className.split(' ').filter(function(c) {
        return c.lastIndexOf('theme-', 0) !== 0;
      });

      html.className = classes.join(' ').trim();
      html.classList.add('theme-' + value);
    });
  });
})();
